import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/main";
import Carrinho from "./screens/carrinho";
import React, { useState } from "react";
import Painel from "./screens/painel";
import Auth from "./screens/auth";

const Router = () => {
    const [carrinho, setCarrinho] = useState([]);

    function addCar(product, preco, quantidade){
        if(carrinho.length > 0){
            let a = true;
            carrinho.forEach((element, key) => {
                if(element.product._id == product._id){
                    let carr = carrinho;
                    carr[key] = {product: element.product, price: element.price, num: element.num + quantidade};
                    setCarrinho([...carr]);
                    a = false;
                } 
            });

            if(a){
                setCarrinho([{product: product,price: preco, num: quantidade}, ...carrinho])
            }
        } else {
            setCarrinho([{product: product,price: preco, num: quantidade}])
        }
    }

    function removeCar(product){
        if(carrinho.length > 0){
            carrinho.forEach((element, key) => {
                if(element.product._id === product._id){
                    let carr = carrinho;
                    if(element.num > 1){
                        carr[key] = {product: element.product, price: element.price, num: element.num - 1}
                        setCarrinho([...carr])
                    } else {
                        if(carrinho.length <= 1){
                            setCarrinho([]);
                        } else {
                            carr.splice(key, 1);
                            setCarrinho([...carr]);
                        }
                    }
                }
            })
        }
    }

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home addCar={addCar} carrinho={carrinho}/>} />
                <Route path="/cart" element={<Carrinho removeCar={removeCar} carrinho={carrinho} addCar={addCar}/>} />
                <Route path="/admin" element={<Painel/>}/>
                <Route path="/login" element={<Auth/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;