import React, { useEffect, useState } from "react";
import PainelAuth from "../../services/painel";
import Service from "../../services/products";

const SPainel = (props) => {
    const [inputTitle, setInputTitle] = useState(props.product.title);
    const [inputDescription, setInputDescription] = useState(props.product.description);
    const [inputPrice, setInputPrice] = useState(props.product.price);
    const [inputImg, setInputImg] = useState(props.product.img);
    const [inputCategory, setInputCategory] = useState(props.product.category);
    const [categorys, setCategorys] = useState([]);

    useEffect(() => {
        fetchCategorys();
    }, []);

    function closeSP(){
        window.history.pushState({}, "", `/admin` );
        props.setSP("");
    }

    async function submitUpdate(){
        try{
            const response = await PainelAuth.update(props.product._id, {title: inputTitle, description: inputDescription, price: parseFloat(inputPrice), img: inputImg, category: inputCategory});
            window.location = "/admin";
            props.setSP("");
        } catch(error){
            console.log(error);
        }
    }

    async function fetchCategorys(){
        try{
            const response = await Service.get();
            setCategorys(response.data.categorys);
            console.log(categorys);
        } catch(error){
            console.log(error);
        }
    } 

    return(
        <div class="page-product">
            <div class="wrapper-product">
                <div class="left-product">
                    <img src={inputImg}/>
                </div>
                <div class="right-product">
                    <div class="title-product">
                    <input className="sp-input-title" type="text" onChange={(event) => setInputTitle(event.target.value)} value={inputTitle}></input>
                    <i class="fa-solid fa-x" onClick={() => closeSP()}></i>
                    </div>
                    <div class="right-info-product">
                    <textarea className="sp-input-description" onChange={(event) => setInputDescription(event.target.value)} value={inputDescription}></textarea>
                    <h6>Img</h6>
                    <input className="sp-input" type="text" onChange={(event) => setInputImg(event.target.value)} value={inputImg}></input>
                    <h6>Categoria</h6>
                    <select value={inputCategory} onChange={(event) => setInputCategory(event.target.value)} >
                        { categorys.map((category) => 
                            <option value={category._id}>{ category.name }</option>
                        )}
                    </select>
                    <div class="wrapper-price">
                        <h4>R$ </h4>
                        <input className="sp-input-price" type="text" onChange={(event) => setInputPrice(event.target.value)} value={inputPrice}></input>
                    </div>
                    </div>
                    <div class="buttons-product">
                    <button class="btn" onClick={() => submitUpdate()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SPainel;