import React, { Fragment, useState } from "react";
import Pedido from "../../services/pedido";

const Compra = (props) => {
    const [campo, setCampo] = useState(0);
    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState(false);
    const [numero, setNumero] = useState("");
    const [errorNumero, setErrorNumero] = useState(false);
    const [rua, setRua] = useState("");
    const [errorRua, setErrorRua] = useState(false);
    const [bairro, setBairro] = useState("");
    const [errorBairro, setErrorBairro] = useState(false);
    const [numEnd, setNumEnd] = useState("");
    const [errorNumEnd, setErrorNumEnd] = useState(false);
    const [complemento, setComplemento] = useState("");
    const [errorComplemento, setErrorComplemento] = useState(false);
    const [list, setList] = useState(false);
    const [cupom, setCupom] = useState("");
    const [pedido, setPedido] = useState("");
    const [linkW, setLinkW] = useState("");
    const [desconto, setDesconto] = useState(0);
    
    function handleChange(event,type) {
        if(type === "name"){
            setName(event.target.value);
        }

        if(type === "numero"){
            setNumero(event.target.value);
        }

        if(type === "rua"){
            setRua(event.target.value);
        }

        if(type === "bairro"){
            setBairro(event.target.value);
        }

        if(type === "numEnd"){
            setNumEnd(event.target.value);
        }

        if(type === "complemento"){
            setComplemento(event.target.value);
        }

        if(type === "cupom"){
            setCupom(event.target.value);
        }
    }

    function verifyButton(){
        let verify = true;

        if(campo == 0){
            if(name.length <= 1){
                setErrorName(true);
                verify = false;
            }
    
            var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'); 
    
            if(!regex.test(numero)){
                setErrorNumero(true);
                verify = false;
            }
        } else if(campo == 1){
            if(rua.length <= 1){
                setErrorRua(true);
                verify = false;
            } else {
                setErrorRua(false);
            }
    
            if(bairro.length <= 1){
                setErrorBairro(true);
                verify = false;
            } else {
                setErrorBairro(false);
            }
    
            if(numEnd.length < 1 && isNaN(parseFloat(numEnd)) && isFinite(numEnd)){
                setErrorNumEnd(true);
                verify = false;
            } else {
                setErrorNumEnd(false);
            }
        }

        if(verify){
            setCampo(campo + 1);
        }

    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    async function handleChangeCupom(event){
        setCupom(event.target.value);

        await timeout(1000);
        await Pedido.cupom({cupom_name: event.target.value})
        .then((el) => {
            if(el.data.length > 0){
                setDesconto(el.data[0].desconto);
            }
        });
    }

    async function makePedido(){
        let newCarr = [];
        
        await props.carrinho.forEach(element => {
            newCarr.push({number: element.num, id_product: element.product._id});
        });

        const response = await Pedido.compra({name, phone: numero, street: rua, bairro, number: numEnd, complement: complemento, products: newCarr, cupom_name: cupom});

        setPedido(response.data.pedido);
    }

    function formatText(text){
        let a = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        let b = a.replace(" ", "%20");

        return b;
    }

    async function makeLink(){
        let name_link = formatText(name);
        let rua_link = formatText(rua);
        let bairro_link = formatText(bairro);

        let l;

        if(!pedido.desconto){
            let total = pedido.price;
            l = "https://api.whatsapp.com/send?phone=5551993458550&text=%F0%9F%91%8B%20Venho%20de%20https://duplancatalogo.com%0APedido:%20"+pedido._id+"%0A%0A*Dados*%0A%0ANome:%20"+name_link+"%0ATelefone:%20"+pedido.phone+"%0ARua:%20"+rua_link+"%0AN%C3%BAmero:%20"+pedido.address.number+"%0ABairro:%20"+bairro_link+"%0AComplemento:%20"+pedido.address.complement+"%0A%0A%F0%9F%92%B2%20Custos%0APre%C3%A7o%20dos%20produtos:%20R$%20"+pedido.price+"%0APre%C3%A7o%20de%20entrega:%20A%20combinar%0ADesconto:%20R$%20"+"0"+"%0ATotal%20a%20pagar:%20R$%20"+total+"%0A%0A%F0%9F%93%9D%20Pedido";
        } else {
            let total = pedido.price;
            l = "https://api.whatsapp.com/send?phone=5551993458550&text=%F0%9F%91%8B%20Venho%20de%20https://duplancatalogo.com%0APedido:%20"+pedido._id+"%0A%0A*Dados*%0A%0ANome:%20"+name_link+"%0ATelefone:%20"+pedido.phone+"%0ARua:%20"+rua_link+"%0AN%C3%BAmero:%20"+pedido.address.number+"%0ABairro:%20"+bairro_link+"%0AComplemento:%20"+pedido.address.complement+"%0A%0A%F0%9F%92%B2%20Custos%0APre%C3%A7o%20dos%20produtos:%20R$%20"+pedido.price+"%0APre%C3%A7o%20de%20entrega:%20A%20combinar%0ADesconto:%20R$%20"+pedido.desconto+"("+pedido.cupom+")%0ATotal%20a%20pagar:%20R$%20"+total+"%0A%0A%F0%9F%93%9D%20Pedido";
        }


        await props.carrinho.forEach((element) => {
            let name_p = formatText(element.product.title);
            l += "%0A%0A-%20x"+element.num+"%20"+name_p+"%20R$%20"+(element.price * element.num).toFixed(2)+"%0A%20%20Pre%C3%A7o%20unit%C3%A1rio%20R$%20"+element.price.toFixed(2);
        });

        l += "%0A%0A%F0%9F%91%86%20Ap%C3%B3s%20enviar%20o%20pedido,%20aguarde%20que%20j%C3%A1%20iremos%20lhe%20atender..";
        setLinkW(l);
    }

    return(
        <div className="wrapper-form-compra">
            <div className="box-form-compra">
                <div className="header-form-compra">
                    { campo > 0 ?
                        <i onClick={() => setCampo(campo - 1)} class="fa-solid fa-chevron-left"></i>
                    :
                        <h4>Seus dados</h4>
                    }
                    <i onClick={() => props.setFinalizar(false)} class="fa-solid fa-x"></i>
                </div>
                <div className="form-compra">
                { pedido == "" ?
                    <form>
                        <div className="top-form-compra">
                            { campo === 0 ? 
                            <Fragment>
                                <div className="single-form-compra">
                                    <h3>Seu nome:</h3>
                                    <input style={{borderColor: errorName ? "#f44336" : "#e0e0e0"}} type="text" value={name} onChange={(event) => handleChange(event, "name")} placeholder="Digite seu nome"/>
                                    <p data-anime="top" className={ errorName ? "animation" : ""}>Seu nome é obrigatório</p>
                                </div>
                                <div className="single-form-compra">
                                    <h3>Seu telefone:</h3>
                                    <input style={{borderColor: errorNumero ? "#f44336" : "#e0e0e0"}} type="text" value={numero} onChange={(event) => handleChange(event, "numero")} placeholder="DDD + número"/>
                                    <p data-anime="top" className={ errorNumero ? "animation" : ""}>Digite um número válido</p>
                                </div>
                            </Fragment>
                            : campo === 1 ?
                            <Fragment>
                                <div className="single-form-compra">
                                    <h3>Sua Rua:</h3>
                                    <input style={{borderColor: errorRua ? "#f44336" : "#e0e0e0"}} type="text" value={rua} onChange={(event) => handleChange(event, "rua")} placeholder="Digite sua Rua"/>
                                    <p data-anime="top" className={ errorRua ? "animation" : ""}>Sua rua é obrigatória</p>
                                </div>
                                <div className="single-form-compra">
                                    <h3>Seu Bairro:</h3>
                                    <input style={{borderColor: errorBairro ? "#f44336" : "#e0e0e0"}} type="text" value={bairro} onChange={(event) => handleChange(event, "bairro")} placeholder="Digite seu Bairro"/>
                                    <p data-anime="top" className={ errorBairro ? "animation" : ""}>Seu bairro é obrigatório</p>
                                </div>
                                <div className="single-form-compra">
                                    <h3>Seu Numero de Endereço:</h3>
                                    <input style={{borderColor: errorNumEnd ? "#f44336" : "#e0e0e0"}} type="text" value={numEnd} onChange={(event) => handleChange(event, "numEnd")} placeholder="Digite seu número"/>
                                    <p data-anime="top" className={ errorNumEnd ? "animation" : ""}>Seu número é obrigatório</p>
                                </div>
                                <div className="single-form-compra">
                                    <h3>Seu Complemento:</h3>
                                    <input style={{borderColor: errorComplemento ? "#f44336" : "#e0e0e0"}} type="text" value={complemento} onChange={(event) => handleChange(event, "complemento")} placeholder="Digite seu complemento"/>
                                    <p data-anime="top" className={ errorComplemento ? "animation" : ""}>Digite um número válido</p>
                                </div>
                            </Fragment>
                            :
                            <div className="wrapper-price-box-compra">
                                <div className="wrapper-price-compra">
                                    <div style={{borderBottomRightRadius: list ? "none" : "5px", borderBottomLeftRadius: list ? "none" : "5px"}} className="price-compra" onClick={() => list ? setList(false) : setList(true) }>
                                        <div className="wrapper-price-compra-left">
                                            <i class="fa-solid fa-coins"></i>
                                            <h3>Valor total: R$ {(props.price - desconto).toFixed(2)}</h3>
                                        </div>
                                        { list ? 
                                            <i class="fa-solid fa-chevron-up"></i>
                                        :
                                            <i class="fa-solid fa-chevron-right"></i>
                                        }  
                                    </div>
                                    <ul style={{height: list ? "auto" : "0px", padding: list ? "10px" : "0px"}} className={list ? "list-compra animation" : "list-compra"} data-anime="top">
                                        { props.carrinho.map((product) => 
                                            <li><span>x{product.num} {product.product.title}</span><span>R$ {(product.product.price * product.num).toFixed(2)}</span></li>
                                        )}
                                        <li><span>Descontos totais:</span><span>R${ desconto }</span></li>
                                        <li className="li-final"><span>Valor total:</span><span>{(props.price - desconto).toFixed(2)}</span></li>
                                    </ul>
                                </div>
                                <div className="cupom">
                                    <h4>Código de cupom de desconto:</h4>
                                    <input type="text" value={cupom} onChange={(event) => handleChangeCupom(event)} placeholder="Opcional"/>
                                </div>
                            </div>
                            }
                        </div>
                            { campo > 1 ? 
                                <button type="button" onClick={() => makePedido()} className="btn">Continue</button>
                            :
                                <button type="button" onClick={() => verifyButton()} className="btn">Continue</button>
                            }
                    </form>
                :
                    <div class="wrapper-linkw">
                        <h3>Obrigado por comprar com a <marque>Duplan</marque>!</h3>
                        <h4>Para finalizar, clique no botão abaixo!</h4>
                        <button type="button" className="btn" onClick={() => makeLink()}><a href={linkW}>Finalizar Pedido</a></button>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default Compra;