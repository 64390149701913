import React, { Fragment } from "react";

function Header(props){

    function handleChange(event) {
        props.setInput(event.target.value);
    }

    return(
    <Fragment>
        <div class="container-large">
        <div class="banner"></div>
        </div>

        <div class="container">
            <header class="header-index">
                <div class="header-left">
                <div class="empresa-logo">
                    <div class="logo-img"></div>
                    <h3>Duplan</h3> 
                </div>
                <div class="empresa-info">
                    <div class="wrapper-empresa-info">
                    <h2>Duplan</h2>
                    <i class="fa-solid fa-share-nodes"></i>
                    </div>
                    <div class="time-delivery">
                    <i class="fa-solid fa-motorcycle"></i>
                    <h4>25 - 45min</h4>
                    </div>
                </div>
                </div>
                <div class="header-right">
                    <a href="https://api.whatsapp.com/send?phone=5551993458550&text=Vim%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!">
                        <i class="fa-brands fa-whatsapp"></i>
                    </a>
                </div>
            </header>

            <div class="menu-desktop" style={{border: props.search ? "none" : ""}}>
                { props.search ? 
                    <div class="search disable" id="search">
                        <div class="wrapper-search">
                            <i class="fa-solid fa-magnifying-glass"></i>
                            <input type="text" value={props.input} onChange={(event) => handleChange(event)} placeholder="Escreva algo para pesquisar"/>
                        </div>
                        <button id="close-search" onClick={() => props.setSearch(false)}>Fechar</button>
                    </div>

                :
                    <Fragment>
                        <i id="search-icon" onClick={() => props.setSearch(true)} class="fa-solid fa-magnifying-glass"></i>
                        <i id="menu-icon" class="fa-solid fa-bars"></i>
                        <ul id="menu">
                        { props.categorys.map((category, key) => 
                            <li onClick={() => {
                                let el = document.getElementById(`c${key}`);
                                el?.scrollIntoView({
                                    behavior: "smooth"
                                })
                            }}>{ category.name }</li>
                        )}
                        </ul>
                    </Fragment>
                }
            </div>
        </div>
    </Fragment>
    )
}

export default Header;