import React, { Fragment, useState, useEffect } from "react";
import Service from "../services/products";
import SPainel from "../components/sp-painel";
import PainelAuth from "../services/painel";
import SPainelNew from "../components/sp-new";
import SPCategory from "../components/add-category";
import CategoryAuth from "../services/category";

const Painel = () => {
    const [categorys, setCategorys] = useState([]);
    const [products, setProducts] = useState([]);
    const [sp, setSP] = useState("");
    const [spNew, setSpNew] = useState(false);
    const [addCategory, setAddCategory] = useState(false);
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        verifyAuth();
        fetchProducts();
        urlSP();
    }, []);

    function verifyAuth(){
        let a = localStorage.getItem('token');

        if(a){
            setAuth(true);
        } else {
            window.location = "/";
        }
    }

    async function urlSP(){
        var params = window.location.search.substring(1).split('&')[0].split("=")[1];

        if(params !== undefined){
            const response = await Service.sp(params);
            setSP(response.data.product);
        }
    }

    async function fetchProducts(){
        try{
            const response = await Service.get();
            setCategorys(response.data.categorys);
            setProducts(response.data.products);
        } catch(error){
            console.log(error);
        }
    } 

    function nav(product){
        window.history.pushState({}, "", `/admin/?product=${product._id}` );
        setSP(product);
    }

    async function deleteProduct(id){
        try{
            const deleteProduct = await PainelAuth.delete(id);
            window.location = "/admin";
        } catch(error){
            console.log(error);
        }
    }

    
    async function deleteCategory(id){
        try{
            const deleteCategory = await CategoryAuth.delete(id);
            window.location = "/admin";
        } catch(error){
            console.log(error);
        }
    }

    function addProduct(){
        setSpNew(true);
    }

    function clickAddCategory(){
        setAddCategory(true);
    }

    return(
        <Fragment>
            { !auth ? false :
            <Fragment>
                <div className="header-painel">
                    <div className="container">
                        <div class="empresa-logo">
                            <div class="logo-img"></div>
                            <h3>Duplan</h3> 
                        </div>
                        <div className="menu-painel">
                            <ul>
                                <li>Cupom</li>
                                <li>Produtos</li>
                                <li>Categorias</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content-painel">
                    <div className="container">
                        <div className="wrapper-buttons-auth">
                            <button class="btn addP" onClick={() => addProduct()}>Adicionar Produto</button>
                            <button class="btn addP" style={{marginLeft: "15px"}} onClick={() => clickAddCategory()}>Adicionar Categoria</button>
                        </div>
                        <div class="products">
                            { categorys.map((category,key) => 
                                <Fragment>
                                    <h2>{ category.name } <a onClick={() => deleteCategory(category._id)}><i class="fa-solid fa-trash"></i></a></h2>
                                    <div class="wrapper-products">
                                        { category.products.map((product) => 
                                        <div class="single-product">
                                            <div class="product-img" onClick={() => nav(product)}>
                                                { product.num ? <span>{ product.num }</span> : "" }
                                                <div className="wrapper-img-product">
                                                    <img src={product.img}/>
                                                </div>
                                            </div>
                                            <div class="product-info">
                                            <h3 onClick={() => nav(product)}>{ product.title }</h3>
                                            <p onClick={() => nav(product)}>{ product.description }</p>
                                            <div class="wrapper-button">
                                                <div class="wrapper-price" onClick={() => nav(product)}>
                                                <h4>R$ { product.price.toFixed(2) }</h4>
                                                </div>
                                                <div class="add-carrinho" onClick={() => deleteProduct(product._id)}>
                                                    <a><i class="fa-solid fa-trash"></i></a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
            }
            {sp !== "" ? <SPainel product={sp} setSP={setSP}/> : ""}
            {spNew === true ? <SPainelNew setSP={setSpNew}/> : ""}
            {addCategory === true ? <SPCategory setCategory={setAddCategory} /> : ""}
        </Fragment>
    )
}

export default Painel;