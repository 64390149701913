import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Compra from "../components/compra";

const Carrinho = (props) => {
    const [price, setPrice] = useState(0);
    const [vazio, setVazio] = useState(true);
    const [finalizar, setFinalizar] = useState(false);
    const [animePriceCar, setAnimePriceCar] = useState(false);

    useEffect(() => {
        if(props.carrinho.length > 0){
            setVazio(false);
            let preco = 0;
            props.carrinho.forEach((element) => {
                preco = preco + (element.price * element.num);
            });
            setPrice(preco);

            setAnimePriceCar(true);
            const timeout = setTimeout(() => {
                setAnimePriceCar(false)
            }, 500);

            return () => clearTimeout(timeout)
        } else {
            setVazio(true);
        }
    }, [props.carrinho]);

    return(
    <Fragment>
        <div class="container-large">
            <header class="menu-carrinho">
                <div class="container">
                    <div class="voltar">
                        <a href="/"><h4>Voltar</h4></a>
                    </div>
                    <div class="price-carrinho">
                        <h4>Sua Sacolinha</h4>
                        <h3 style={{display: vazio ? "none" : "block"}} className={animePriceCar ? "setAnimePrice" : ""}>R$ { price.toFixed(2) }</h3>
                    </div>
                </div>
            </header>

            <div class="container">
                { vazio ?
                <div className="page-vazio">
                    <div className="svg"></div>
                    <h3>Sua cesta está vazia</h3>
                    <h4>Parece que você ainda não adicionou nenhum produto</h4>
                    <Link to="/"><button class="btn">Continue comprando</button></Link>
                </div>

                :

                <div class="products-carrinho">
                    { props.carrinho.map((product) => 
                    <div class="sigle-carrinho">
                        <div class="img-p-carrinho">
                            <img src={product.product.img}/>
                        </div>
                        <div class="carrinho-info">
                            <h3>{ product.product.title }</h3>
                            <div class="wrapper-button">
                                <div class="wrapper-price">
                                    <h4>R$ { product.price }</h4>
                                </div>
                                <div class="options-carrinho">
                                    { product.num > 1 ?
                                        <i onClick={() => props.removeCar(product.product)} class="fa-solid fa-minus"></i>
                                    :
                                        <i onClick={() => props.removeCar(product.product)} class="fa-solid fa-trash"></i>
                                    }
                                    <h3>{ product.num }</h3>
                                    <i onClick={() => props.addCar(product.product, product.price, 1)} class="fa-solid fa-plus"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                }
            </div>
        </div>

        <div class="fixed-menu fixed-menu-carrinho" style={{display: vazio ? "none" : "block"}}>
            <div class="container">
                <h3>Entre em contato</h3>
                <button className="btn" onClick={() => setFinalizar(true)}>Finalizar meu Pedido</button>
            </div>
        </div>

        { finalizar ? <Compra carrinho={props.carrinho} price={price} setFinalizar={setFinalizar}/> : "" }
    </Fragment>
    )
}

export default Carrinho;