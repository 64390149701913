import React, { useState } from "react";

const SP = (props) => {
    const [num, setNum] = useState(1);

    function closeSP(){
        window.history.pushState({}, "", `/` );
        props.setSP("");
    }

    return(
        <div class="page-product">
            <div class="wrapper-product">
                <div class="left-product">
                    <img src={props.product.img}/>
                </div>
                <div class="right-product">
                    <div class="title-product">
                    <h3>{ props.product.title }</h3>
                    <i class="fa-solid fa-x" onClick={() => closeSP()}></i>
                    </div>
                    <div class="right-info-product">
                    <p>{ props.product.description }
                    </p>
                    <div class="wrapper-price">
                        <h4>R$ { props.product.price.toFixed(2) }</h4>
                    </div>
                    </div>
                    <div class="buttons-product">
                    <div class="wrapper-num">
                        <i onClick={() => {if(num > 1){setNum(num - 1)}}} class="fa-solid fa-minus"></i>
                        <span>{ num }</span>
                        <i onClick={() => setNum(num + 1)} class="fa-solid fa-plus"></i>
                    </div>
                    <button onClick={() => {props.addCar(props.product, props.product.price, num); closeSP();}} class="btn">Adicionar R${ (props.product.price * num).toFixed(2) }</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SP;