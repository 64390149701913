import Api from "./api";

const CategoryAuth = {
    create: (params) => Api.post(`/category/create`, params,{
        headers: {'x-access-token': localStorage.getItem('token')}
    }),
    delete: (id) => Api.delete(`/category/${id}`, {
        headers: {'x-access-token': localStorage.getItem('token')}
    })
}

export default CategoryAuth;