import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../components/header";
import Service from "../services/products";
import Products from "../components/products";
import MenuFixed from "../components/menu-fixed";
import SP from "../components/sp";
import Search from "../components/search";

const Home = (props) => {
    const [categorys, setCategorys] = useState([]);
    const [products, setProducts] = useState([]);
    const [sp, setSP] = useState("");
    const [search, setSearch] = useState(false);
    const [input, setInput] = useState("");

    useEffect(() => {
        fetchProducts();
        urlSP();
    }, []);

    async function urlSP(){
        var params = window.location.search.substring(1).split('&')[0].split("=")[1];

        if(params !== undefined){
            const response = await Service.sp(params);
            setSP(response.data.product);
        }
    }

    async function fetchProducts(){
        try{
            const response = await Service.get();
            setCategorys(response.data.categorys);
            setProducts(response.data.products);
        } catch(error){
            console.log(error);
        }
    } 

    const refs = useRef([]);

    return(
        <Fragment>
            <Header refs={refs} input={input} setInput={setInput} categorys={categorys} setSearch={setSearch} search={search}/>
            { !search ?
                <Products refs={refs} setCategorys={setCategorys} carrinho={props.carrinho} setSP={setSP} addCar={props.addCar} categorys={categorys}/>
            :
                <Search products={products} input={input} carrinho={props.carrinho} setSP={setSP} addCar={props.addCar} />
            }
            <MenuFixed carrinho={props.carrinho} />
            {sp !== "" ? <SP product={sp} carrinho={props.carrinho} addCar={props.addCar} setSP={setSP}/> : ""}
            <footer>
                <div className="container">
                    <p>Todos os direitos reservados a Duplan</p>
                </div>
            </footer>
        </Fragment>
    )
}

export default Home;