import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../services/users";

const Auth = () => {
    const [inputEmail, setInputEmail] = useState("");
    const [InputSenha, setInputSenha] = useState("");
    const [RedirectAdminHome, setRedirectAdminHome] = useState(false);
    const [error, setError] = useState(false);

    async function login(){
        await UserService.login({email: inputEmail, password: InputSenha}).then((data) => {
            console.log(data);
            if(data.data.error){
                setError(true);
            } else {
                setRedirectAdminHome(true);
            }
        });
    }

    let navigate = useNavigate();

    if(RedirectAdminHome)
        navigate("/admin");

    return(
        <Fragment>
            <div className="login">
                <div className="container">
                    <div className="box">
                        <h4>Login</h4>
                        <div className="wrapper-input">
                            <h5>Username</h5>
                            <div className="wrapper-input-icon">
                                <i class="fa-solid fa-user"></i>
                                <input type="text" value={inputEmail} onChange={(event) => setInputEmail(event.target.value)} placeholder="Digite o seu usuario..."></input>
                            </div>
                        </div>
                        <div className="wrapper-input">
                            <h5>Senha</h5>
                            <div className="wrapper-input-icon">
                                <i class="fa-regular fa-eye"></i>
                                <input type="password" value={InputSenha} onChange={(event) => setInputSenha(event.target.value)} placeholder="Digite a sua senha..."></input>
                            </div>
                        </div>
                        { error && <div className="danger">Email or Password Invalid</div>}
                        <button className="btn" onClick={() => login()}>Entrar</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Auth;