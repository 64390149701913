import React, { useState } from "react";
import CategoryAuth from "../../services/category";

const SPCategory = (props) => {
    const [name, setName] = useState("")

    function closeSP(){
        props.setCategory("");
    }

    async function submitPost(){
        try{
            const response = await CategoryAuth.create({name: name}).then((data) => {
                window.location = `/admin`;
                props.setSP("");
            })
        } catch(error){
            console.log(error);
        }
    }

    return(
        <div class="page-product sp-category">
            <div class="wrapper-product">
                <i class="fa-solid fa-x" onClick={() => closeSP()}></i>
                <h4>Adicionar Categoria</h4>
                <div className="wrapper-input">
                    <h5>Nome da Categoria</h5>
                    <div className="wrapper-input-icon">
                        <i class="fa-regular fa-pen-to-square"></i>
                        <input type="text" value={name} onChange={(event) => setName(event.target.value)} placeholder="Digite a categoria..."></input>
                    </div>
                    <button class="btn" onClick={() => submitPost()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default SPCategory;