import React, { useState, useEffect } from "react";
import PainelAuth from "../../services/painel";
import Service from "../../services/products";

const SPainelNew = (props) => {
    const [inputTitle, setInputTitle] = useState("Title");
    const [inputDescription, setInputDescription] = useState("Descrição");
    const [inputPrice, setInputPrice] = useState("00.00");
    const [inputImg, setInputImg] = useState("Digite a Url da Imagem");
    const [inputCategory, setInputCategory] = useState("");
    const [categorys, setCategorys] = useState([]);

    useEffect(() => {
        fetchCategorys();
    }, []);

    function closeSP(){
        props.setSP("");
    }

    async function submitPost(){
        try{
            const response = await PainelAuth.create({title: inputTitle, description: inputDescription, price: parseInt(inputPrice), img: inputImg, category: inputCategory});
            window.location = `/admin`;
            props.setSP("");
        } catch(error){
            console.log(error);
        }
    }

    async function fetchCategorys(){
        try{
            const response = await Service.get();
            setCategorys(response.data.categorys);
            console.log(categorys);
        } catch(error){
            console.log(error);
        }
    } 

    return(
        <div class="page-product">
            <div class="wrapper-product">
                <div class="left-product">
                    <img src={inputImg}/>
                </div>
                <div class="right-product">
                    <div class="title-product">
                    <input className="sp-input-title" type="text" onChange={(event) => setInputTitle(event.target.value)} value={inputTitle}></input>
                    <i class="fa-solid fa-x" onClick={() => closeSP()}></i>
                    </div>
                    <div class="right-info-product">
                    <textarea className="sp-input-description" onChange={(event) => setInputDescription(event.target.value)} value={inputDescription}></textarea>
                    <h6>Img</h6>
                    <input className="sp-input" type="text" onChange={(event) => setInputImg(event.target.value)} value={inputImg}></input>
                    <h6>Categoria</h6>
                    <select value={inputCategory} onChange={(event) => setInputCategory(event.target.value)} >
                        { categorys.map((category) => 
                            <option value={category._id}>{ category.name }</option>
                        )}
                    </select>
                    <div class="wrapper-price">
                        <h4>R$ </h4>
                        <input className="sp-input-price" type="text" onChange={(event) => setInputPrice(event.target.value)} value={inputPrice}></input>
                    </div>
                    </div>
                    <div class="buttons-product">
                    <button class="btn" onClick={() => submitPost()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SPainelNew;