import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MenuFixed = (props) => {
    const [quant, setQuant] = useState(0);
    const [price, setPrice] = useState(0);
    const [animePrice, setAnimePrice] = useState(false);

    useEffect(() => {
        setAnimePrice(false);
        if(props.carrinho.length > 0){
            let quantidade = 0;
            let preco = 0;
            props.carrinho.forEach((element) => {
                preco = preco + (element.price * element.num);
                quantidade = quantidade + element.num;
            });
            setQuant(quantidade);
            setPrice(preco);

            setAnimePrice(true);
            const timeout = setTimeout(() => {
                setAnimePrice(false)
            }, 500);

            return () => clearTimeout(timeout)
        }
    }, [props]);

    return (
        <div class="fixed-menu" style={{display: quant > 0 ? "block" : "none"}}>
            <div class="container">
                <div class="fixed-info">
                    <h4 id="num-product"  className={animePrice ? "setAnimePrice" : ""}>{ quant } produtos</h4>
                    <h3 id="price" className={animePrice ? "setAnimePrice" : ""}>R$ { price.toFixed(2) }</h3>
                </div>
                <Link to="/cart"><button class="btn">Veja meu pedido</button></Link>
            </div>
        </div>
    )
}

export default MenuFixed;