import React, { Fragment, useEffect } from "react";

const Products = (props) => {

    useEffect(() => {
        props.categorys.forEach((category, keyCa) => {
            props.carrinho.forEach((product) => {
                if(category._id === product.product.category){
                    let cate = props.categorys;
                    category.products.forEach((el,key) => {
                        if(el._id === product.product._id){
                            cate[keyCa]["products"][key]["num"] = product.num;
                            props.setCategorys([...cate]);
                            return;
                        }
                    });
                }
            })
        });

    }, [props.carrinho]);

    function nav(product){
        window.history.pushState({}, "", `?product=${product._id}` );
        props.setSP(product);
    }

    return(
        <div className="container">
            <div class="products">
                { props.categorys.map((category,key) => 
                    <Fragment>
                        <h2 ref={el => props.refs.current[key] = el} id={"c"+key}>{ category.name }</h2>
                        <div class="wrapper-products">
                            { category.products.map((product) => 
                            <div class="single-product">
                                <div class="product-img" onClick={() => nav(product)}>
                                    { product.num ? <span>{ product.num }</span> : "" }
                                    <div className="wrapper-img-product">
                                        <img src={product.img}/>
                                    </div>
                                </div>
                                <div class="product-info">
                                <h3 onClick={() => nav(product)}>{ product.title }</h3>
                                <p onClick={() => nav(product)}>{ product.description }</p>
                                <div class="wrapper-button">
                                    <div class="wrapper-price" onClick={() => nav(product)}>
                                    <h4>R$ { product.price.toFixed(2) }</h4>
                                    </div>
                                    <div class="add-carrinho" onClick={() => props.addCar(product, product.price, 1)}>
                                        <a><i class="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default Products;