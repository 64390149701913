import Api from "./api";

const UserService = {
    login: async(params) => {
        const response = await Api.post('/users/login', params);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('token', response.data.token);
        return response;
    }
}

export default UserService;