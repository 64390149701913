import Api from "./api";

const PainelAuth = {
    update: (id,params) => Api.put(`/products/${id}/edit`, params, {
        headers: {'x-access-token': localStorage.getItem('token')}
    }),
    delete: (id) => Api.delete(`/products/${id}`, {
        headers: {'x-access-token': localStorage.getItem('token')}
    })
}

export default PainelAuth;