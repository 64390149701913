import React, { Fragment, useEffect, useState } from "react";

const Search = (props) => {
    const [prodSearch, setProdSearch] = useState([]);

    useEffect(() => {
        let arr = [];
        props.products.forEach((element) => {
            if(element.title.indexOf(props.input) !== -1){
                arr.push(element);
            }
        });

        setProdSearch([...arr]);
    },[props.input]);

    function navs(product){
        window.history.pushState({}, "", `?product=${product._id}` );
        props.setSP(product);
    }

    return(
        <div className="container">
            <div class="products" style={{marginTop: "30px"}}>
                <Fragment>
                    <div class="wrapper-products">
                        { prodSearch.map((product) => 
                        <div class="single-product">
                            <div class="product-img" onClick={() => navs(product)}>
                                { props.carrinho.map((element) => {
                                    <span>{ element.product.title }</span>
                                })}
                                <div className="wrapper-img-product">
                                    <img src={product.img}/>
                                </div>
                            </div>
                            <div class="product-info">
                            <h3>{ product.title }</h3>
                            <p>{ product.description }</p>
                            <div class="wrapper-button">
                                <div class="wrapper-price">
                                <h4>R$ { product.price.toFixed(2) }</h4>
                                </div>
                                <div class="add-carrinho" onClick={() => props.addCar(product, product.price, 1)}>
                                    <a><i class="fa-solid fa-plus"></i></a>
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                    </div>
                </Fragment>
            </div>
        </div>
    )
}

export default Search;